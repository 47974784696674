<template>
  <div >
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    mounted(){
        this.logout();
    },
    methods:{
        ...mapActions(['logout']),
        signout(){
            this.logout();
        }
    }
}
</script>

<style>

</style>